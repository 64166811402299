import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { Navbar} from 'react-bootstrap'
import { useStaticQuery, graphql } from "gatsby"
import {isMobile, isTablet} from "react-device-detect";

import ImageModule from "gatsby-theme-starberry-lomondgroup/src/modules/image_module.js";


// Images

const HeaderLogo = (props) => {

  const data = useStaticQuery(graphql`
  query HeaderLogoQueryTheme{
    glstrapi {

      siteConfig {
        Header_Logo {
          url
          alternativeText
        }
        Header_Bg_White_Logo{
          url
          alternativeText
        }
        Collection_Logo {
          alternativeText
          url
        }
      }
    }
  }`)

  var header_data = data.glstrapi;

  var site_logo = (typeof window !== "undefined" && window.innerWidth < 1200) ? header_data?.siteConfig?.Header_Bg_White_Logo : header_data?.siteConfig?.Header_Logo

  //console.log("stickyHeader ==>", typeof window !== "undefined" && window.innerWidth < 1200 ? "bg_white" : "white")

  return (
    <React.Fragment>
      <Navbar.Brand>
        <Link to="/">
          {
          props?.scroll ?
            <ImageModule ImageSrc={header_data?.siteConfig?.Header_Bg_White_Logo} altText={"Logo"} className="logo 1"/>
          :
          <>
            {(props?.GQLPage?.layout === "landing_template" || props?.GQLPage?.layout === "static_template" || props?.GQLPage?.layout === "team_template") ? (
              props?.GQLPage?.menu?.slug === "collection" ?
                <ImageModule ImageSrc={header_data?.siteConfig?.Collection_Logo ? header_data?.siteConfig?.Collection_Logo : header_data?.siteConfig?.Header_Logo} altText={"Logo"} className="logo 2"/>
              :
                <ImageModule ImageSrc={site_logo} altText={"Logo"} className="logo 4"/>
              )
            :
            props?.GQLPage?.layout === "collection_page_template" ?
              <ImageModule ImageSrc={header_data?.siteConfig?.Collection_Logo ? header_data?.siteConfig?.Collection_Logo : header_data?.siteConfig?.Header_Logo} altText={"Logo"} className="logo"/>
              :
              <ImageModule ImageSrc={header_data?.siteConfig?.Header_Bg_White_Logo ? header_data?.siteConfig?.Header_Bg_White_Logo : header_data?.siteConfig?.Header_Logo} altText={"Logo"} className="logo"/>
            }
          </>
          }                      
        </Link>
      </Navbar.Brand>
    </React.Fragment>
  )
}



export default HeaderLogo